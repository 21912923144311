import React, { useState } from 'react';
import Review from "../../components/organs/review";
import SendMessage from "../../components/organs/SendMessage";
// import { parsePhoneNumberFromString } from 'libphonenumber-js';

import Main from "../../layout/Main";
import { Link } from "react-router-dom";

import image1 from "../../assets/images/image1.png"
import image2 from "../../assets/images/image2.png"
import image3 from "../../assets/images/image3.png"
import image4 from "../../assets/images/image4.png"
import image5 from "../../assets/images/image5.png"
import image6 from "../../assets/images/image6.png"
import image7 from "../../assets/images/image7.png"
import image8 from "../../assets/images/image8.png"
import image9 from "../../assets/images/image9.png"
import image10 from "../../assets/images/image10.png"
import image11 from "../../assets/images/image11.png"
import image12 from "../../assets/images/image12.png"
// import image13 from "../../assets/images/image13.png"
import sliderImage1 from "../../assets/images/slider-image-1.png"
import sliderImage2 from "../../assets/images/slider-image-2.png"
import sliderImage3 from "../../assets/images/slider-image-3.png"
import sliderImage4 from "../../assets/images/slider-image-4.png"
import sliderImage5 from "../../assets/images/slider-image-5.png"

import "./styles.scss";


const Home = () => {

  const images = [sliderImage1, sliderImage2, sliderImage3, sliderImage4, sliderImage5];


  return (
    <Main className="home-page">
      <div className="wrapper">
        <h1 className="a-heading1">
          Empowerment Through
          <div className="edit">
            Compassionate Care
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="533"
              height="16"
              viewBox="0 0 533 16"
              fill="none"
            >
              <path
                d="M0.147461 13.9452C0.147461 13.9452 166.274 1.6865 273 2.00005C374.46 2.29814 532.347 13.9452 532.347 13.9452"
                stroke="#FF4C98"
                stroke-width="2.53428"
              />
            </svg>
          </div>
        </h1>

        <h2 className="a-heading2">
          Discover Support Tailored to Your Goals at All in 1 Care. Let Us
          Elevate Your Journey Toward Independence and Well-being.
        </h2>

        <section className="a-section1">
          <div className="wrapper">
            <div className="img1 img-wrapper">
              <img src={image1} alt="View our program" />
              <div className="content-wrapper content-wrapper-1">
                <p>Transforming lives with our support programs</p>
                <Link to="/">View Our program</Link>
              </div>
            </div>
            <div className="img2 img-wrapper">
              <img src={image2} alt="How can we help you" />
              <div className="content-wrapper content-wrapper-2">
                <Link className="btn" to="/">
                  How can we help you?
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5 12L19 12"
                      stroke="#F8FAFC"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 5L19 12L12 19"
                      stroke="#F8FAFC"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="img3 img-wrapper">
              <img src={image3} alt="Learn about us" />
              <div className="content-wrapper content-wrapper-3">
                <p>
                  Learn Our Passionate Story and Commitment to Empowering Lives{" "}
                </p>
                <Link href="/about">Learn about us</Link>
              </div>
            </div>
          </div>
        </section>

        <section className="a-section2">
          <div className="wrapper">
            <div className="left">
              <h2>
                We take care of your loved ones
                <span>
                  like our Family
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="338"
                    height="12"
                    viewBox="0 0 338 12"
                    fill="none"
                  >
                    <path
                      d="M1 10C1 10 95.9822 1.59579 178.202 2.01519C256.366 2.4139 337.5 10 337.5 10"
                      stroke="#FF4C98"
                      stroke-width="2.53428"
                    />
                  </svg>
                </span>
              </h2>
              <p>
                Founded in 2020 by experienced healthcare professionals, All in
                1 Care is dedicated to empowering individuals with psychosocial
                disabilities. Our founding team, with over two decades of
                collective experience as registered nurses in Australian
                healthcare, noticed prevalent gaps in NDIS support. Leveraging
                our extensive background in public mental health and disability
                services, we offer personalized care aligned with your goals. We
                meticulously match aspirations with our skilled support staff
                and provide 24/7 assistance through dedicated registered nurses,
                ensuring optimal outcomes even in challenging situations.
              </p>
              <Link className="btn" to="/about">
                Learn more about us
              </Link>
            </div>
            <div className="right">
              <div className="img-wrapper">
                <img src={image4} alt="10year" />
                <div className="content-wrapper content-wrapper-1">
                  <p className="type1">12+ Years</p>
                  <p className="type2">
                    Our skilled team offers more than 12 years of committed medical experience.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="a-section3">
          <div className="wrapper">
            <h2>
              How we can support <br /> you to
              <span>
                {" "}
                get more from life
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="376"
                  height="12"
                  viewBox="0 0 376 12"
                  fill="none"
                >
                  <path
                    d="M1 10C1 10 106.567 1.59579 197.95 2.01519C284.824 2.4139 375 10 375 10"
                    stroke="#FF66A7"
                    stroke-width="2.53428"
                  />
                </svg>
              </span>
            </h2>
            <div className="cards-wrapper">
              <div className="card">
                <img
                  src={image5}
                  alt="In-home care"
                />
                <h3> In-home care </h3>
                <p>
                  Assistance with daily living activities, such as personal care, meal preparation, and light housekeeping.
                </p>
              </div>
              <div className="card">
                <img
                  src={image6}
                  alt="Nursing care"
                />
                <h3> Nursing care </h3>
                <p>
                  Skilled nursing services, including wound care, medication management, and chronic disease management.
                </p>
              </div>
              <div className="card">
                <img
                  src={image7}
                  alt="Rehabilitation therapy"
                />
                <h3> Rehabilitation therapy </h3>
                <p>
                  Comfortable accommodation offering short-term relief and
                  support for participants and caregivers
                </p>
              </div>
              <div className="card">
                <img
                  src={image8}
                  alt="Social support"
                />
                <h3> Social support </h3>
                <p>
                  Companionship, emotional support, and help with errands and transportation.
                </p>
              </div>
              <div className="card">
                <img
                  src={image9}
                  alt="Social support"
                />
                <h3> Respite care </h3>
                <p>
                  Temporary relief for family caregivers, providing a break from caregiving responsibilities.
                </p>
              </div>
              <div className="card contact-card">
                <div>
                  <h3>Do you have any specific requirements?</h3>
                  <p>
                    Reach out to us with any special request. we try best to
                    find solution to all your problems.
                  </p>
                </div>
                <Link className="btn" to="/contact">
                  Contact us for help!
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="a-section4">
          <div className="wrapper">
            <h2>
              Participant Reviews - Our Support in
              <span>
                Their Words
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="269"
                  height="10"
                  viewBox="0 0 269 10"
                  fill="none"
                >
                  <path
                    d="M2 8C2 8 84.7202 1.79547 137.863 2.0052C188.383 2.20458 267 8 267 8"
                    stroke="#FF4C98"
                    stroke-width="2.53428"
                    stroke-linecap="round"
                  />
                </svg>
              </span>
            </h2>

          </div>

          <div className="marquee-container">
            <div className="marquee-content">
              {images.map((image, index) => (
                <img src={image} alt={`Slider Image ${index + 2}`} key={index} />
              ))}
              {images.map((image, index) => (
                <img src={image} alt={`Slider Image ${index + 2} repeat`} key={`repeat-${index}`} />
              ))}
            </div>
          </div>
        </section>

        <Review />

        <section className="a-section5">
          <div className="wrapper">
            <h2>Get Inspired from our latest Blogs</h2>
            <div className="cards-wrapper">
              <div className="card">
                <img
                  src={image10}
                  alt="Supported Independent Living"
                />
                <h3>Supported Independent Living</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Ligula at quam mattis
                  risus neque.{" "}
                </p>
                <Link to="/">Read More</Link>
              </div>
              <div className="card">
                <img
                  src={image11}
                  alt="Supported Independent Living"
                />
                <h3>Supported Independent Living</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Ligula at quam mattis
                  risus neque.{" "}
                </p>
                <Link to="/">Read More</Link>
              </div>
              <div className="card">
                <img
                  src={image12}
                  alt="Supported Independent Living"
                />
                <h3>Supported Independent Living</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Ligula at quam mattis
                  risus neque.{" "}
                </p>
                <Link to="/">Read More</Link>
              </div>
            </div>
          </div>
        </section>

        <SendMessage />
      </div>
    </Main>
  );
};

export default Home;

