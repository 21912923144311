import axios from "axios";
const API_URL = "https://dynamicapi.memeticsolutions.in/api"; //process.env?.REACT_APP_API_URL 

const getToken = () => {
    let token = localStorage.getItem('allin1care-token');
    return token;
}

const getClientToken = async () => {
    const host = 'allin1care.com.au';
    const resp = await axios.get(`${API_URL}/Client/GetToken/${host}`);
    localStorage.setItem("allin1care-token", resp?.data);
    return resp.data;
}

const getRequest = async (url, headers) => {
    let token  = getToken();
    if(!token) {
        token = await getClientToken();
    }
    const myheaders = { 
        headers: {
            "Authorization" : `Bearer ${token}`,
            'Content-Type': 'application/json'
        } 
    }
    try {
        const resp = await axios.get(`${API_URL}/${url}`, {...myheaders, ...headers});
        return resp
    } catch (err) {
        if (err?.response?.status === 401) {
            localStorage.clear();
            return await getRequest(url, headers);
        }

        return err
    }
    
}

const postRequest = async (url, params, headers) => {
    let token  = getToken();
    if(!token) {
        token = await getClientToken();
    }
    const myheaders = { 
        headers: {
            "Authorization" : `Bearer ${token}`
        } 
    }
    try {
        const resp = await axios.post(`${API_URL}/${url}`, params, {...myheaders, ...headers});
        return resp
    } catch (err) {
        if (err.response.status === 401) {
            localStorage.clear();
            return await postRequest(url, params, headers);
        }

        return err
    }
    
}

export { getRequest, postRequest }