import React, { useState } from "react";
import './styles.scss';
import star from '../../../assets/icons/Star.svg';

const Review = () => {

    const reviews = [
        {
            text: "Working with All in 1 Care has been life-changing. Their dedicated team provided personalized support that exceeded my expectations. I feel more independent and empowered in my daily life. Thank you for your exceptional care and commitment.",
        },
        {
            text: "As a support worker, my daily responsibilities are diverse, with each client having unique needs and goals. My role involves facilitating clients to achieve their goals, ensuring they access the services they require, maintaining their living conditions, and providing companionship. I find great satisfaction in seeing people overcome challenges and being part of positive changes in their lives. One example of my support involved helping a client regain their independence by assisting with paperwork to get their vehicle back on the road. All in 1 Care has invested in my growth, providing training and courses, and I genuinely appreciate the support and respect I receive. Working for a company I truly admire is a privilege.",
        }
    ];

    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const Review = ({ review, isExpanded, onToggle }) => {
        const words = review.text.split(' ');
        const shouldShorten = words.length > 42;
        const textToShow = shouldShorten && !isExpanded ? words.slice(0, 42).join(' ') + '...' : review.text;

        return (
            <div className="review">
                <div className="stars">
                    {[...Array(5)].map((_, i) => (
                        <img key={i} src={star} alt="star" />
                    ))}
                </div>
                <p>{textToShow}</p>
                {shouldShorten && (
                    <div className="read-more" onClick={onToggle}>
                        {isExpanded ? 'Read less' : 'Read more'}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="review-wrapper">
            <div className="wrapper">
                <div className="boxes">
                    {reviews.map((review, index) => (
                        <Review
                            key={index}
                            review={review}
                            isExpanded={index === expandedIndex}
                            onToggle={() => toggleExpand(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Review;
