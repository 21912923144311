import "./style.scss";
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { getRequest } from "../../../utils/axios";
import { useParams } from "react-router-dom";

import logowhite from "../../../assets/icons/full-logo.svg"
import ndisLogo from "../../../assets/icons/ndis-logo.svg";
import ndisImage from "../../../assets/icons/ndis-image.svg";
import facebook from "../../../assets/icons/facebook.svg";
import linkedin from "../../../assets/icons/linkedin.svg";
import twitter from "../../../assets/icons/Twitter.svg";

const Footer = () => {

  const [privacy, setPolicy] = useState([]);

  useEffect(() => {
    getPolicy()
  }, [])

  const getPolicy = async () => {
    const response = await getRequest(`Page/GetActivePagesByCategoryTypeName/pages`);
    setPolicy(response.data);
    localStorage.setItem('policyData', JSON.stringify(response.data));
  }

  return (
    <footer className='a-footer'>
      <div className="wrapper">

        <div className="content-wrapper">

          <div className='row row1'>
            <div className="left">
              <div className="image">
                <Link to="/" >
                  <img src={logowhite} alt='logo' className="logo" />
                </Link>
                <div className="ndis">
                  <img src={ndisImage} />
                </div>
              </div>
            </div>

            <div className='right'>
              <img src={ndisLogo} />
            </div>
          </div>

          <div className='row row2'>
            <div className='left'>
              <div className='item'>
                Call us <br /> <a href='tel:+61478420040'>  +61 478 420 040 </a>
              </div>
              <div className='item'>
                Email us at <br /> <a href='mailto:info@allin1care.com.au'>info@allin1care.com.au</a>
              </div>
            </div>
            <div className='right'>
              <div className='item'>
                Important Links
              </div>
              <nav>
                <ul>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  <li>
                    <Link to='/services'>Services</Link>
                  </li>
                  <li>
                    <Link to='/about'>About</Link>
                  </li>
                  <li>
                    <Link to='/'>Blogs</Link>
                  </li>
                  <li>
                    <Link to='/contact'>Contact us</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div className='row row3'>
            <div className='left'>
              <div className="circle">
                <Link> <img src={linkedin} /> </Link>
              </div>
              <div className="circle">
                <Link to="https://www.facebook.com/profile.php?id=100085218183148&mibextid=LQQJ4d" target="_blank"> <img src={facebook} /> </Link>
              </div>
              <div className="circle">
                <Link> <img src={twitter} /> </Link>
              </div>
            </div>

            {privacy.map((policyData) => (
              <div className="right" key={policyData.pageId}>
                <Link to={`/pages/${policyData.pageUrl}`}> {policyData.pageTitle} </ Link>
                <Link to="/"> Terms & Conditions </ Link>

              </div>
            ))}

          </div>
        </div>

        <div className="copyright">
          <p>Copyright allin1care 2024</p>
        </div>
      </div>

    </footer>
  );
};

export default Footer;