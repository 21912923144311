
import React, { useEffect, useState } from 'react';
import { getRequest } from '../../utils/axios';
import { useParams } from "react-router-dom";
import NoContent from '../../components/atoms/NoContent';
import Main from "../../layout/Main";

import "./styles.scss";

const PrivacyPolicy = () => {

    const [desc, setDesc] = useState("");
    const { categoryTypeName, pageUrl } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => { 
        getWhatsNewInfo();
      }, [])

    const getWhatsNewInfo = async ()  => {
        setLoading(true);
        const response = await getRequest(`Page/GetPageByUrl/${categoryTypeName}/${pageUrl}`);
        setDesc(response?.data?.pageDescription);
        setLoading(false);
    }

    return (
        <Main className="v-policy-wrapper">
         
            {desc ? (
                <div dangerouslySetInnerHTML={{ __html: desc }} ></div>
            ) : (
                <NoContent />
            )}
  
        </Main>
    );
};

export default PrivacyPolicy;

