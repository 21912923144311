import React, { useEffect, useState } from 'react';
import { getRequest } from '../../utils/axios';
import { useParams } from "react-router-dom";
import './styles.scss';
import Loader from '../../components/atoms/Loader';
import Main from '../../layout/Main';
import NoContent from '../../components/atoms/NoContent';
// import Main from '../../layout/Main';

const PagesInfo = () => {

  const { categoryTypeName, pageUrl } = useParams();
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => { 
    getWhatsNewInfo(3);
  }, [])

  const getWhatsNewInfo = async (instituteId = 3) => {
    setLoading(true);
    const response =  await getRequest(`Page/GetPageByUrl/${categoryTypeName}/${pageUrl}`);
    setDesc(response?.data?.pageDescription);
    setLoading(false);
  }

  return (
    <Main className="v-whats-new-info-wrapper">

      <Loader loading={loading} />
      {desc ? (
        <>
                <div dangerouslySetInnerHTML={{__html: desc}} ></div>

        </>
        
      ) : (
        <NoContent />
      )}

    </Main>
  );
}

export default PagesInfo;