import React, { Fragment } from "react";
import Header from "../../components/organs/header";
import Footer from "../../components/organs/footer";

import "./styles.scss";

const Main = ({ children, className }) => {
    return (
        <Fragment>
            <Header />
            <main className={`v-public-main ${className}`}>
                {children}
            </main>
            <Footer />
        </Fragment>
    );
}


export default Main;