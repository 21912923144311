import "./styles.scss";
import React, { useState } from 'react';

import Main from "../../layout/Main";

import ErrorMsg from "../../components/atoms/ErrorMsg/index";
import { postRequest } from "../../utils/axios";
import { validateEmail, validatePhoneNumber } from "../../utils/utils";

import { Link } from "react-router-dom";

const Contact = () => {

  const emptyData = {
    fullName: '',
    businessEmail: '',
    phoneNo: '',
    description: ''
  }

  const errorObj = {
    fullName: '',
    businessEmail: '',
    phoneNo: '',
    description: ''
  }

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(emptyData)
  const [errors, setErrors] = useState(errorObj);

  const handleClose = () => {
    setErrors(errorObj);
    setData(emptyData);
  }

  const handleChange = (value, field) => {
    setData(prev => ({ ...prev, [field]: value }));
    if (errors[field] && value.trim() !== '') {
      setErrors(prev => ({ ...prev, [field]: '' }));
    }
  };

  const handleValidation = () => {
    const errorObj = {};
    let validated = false;
    if (data.fullName === '') {
      errorObj.fullName = 'Please enter your full name';
      validated = true;
    } else {
      errorObj.fullName = '';
    }

    if (data.businessEmail === '') {
      errorObj.businessEmail = 'Please enter your email';
      validated = true;
    } else if (!validateEmail(data.businessEmail)) {
      errorObj.businessEmail = 'Please enter valid email';
      validated = true;
    }
    else {
      errorObj.businessEmail = '';
    }

    if (data.phoneNo === '') {
      errorObj.phoneNo = 'Please enter phone no.';
      validated = true;
    } else if (!validatePhoneNumber(data.phoneNo)) {
      errorObj.phoneNo = 'Please enter valid phone no.';
      validated = true;
    }

    if (data.description === '') {
      errorObj.description = 'Please enter description';
      validated = true;
    } else {
      errorObj.description = '';
    }

    setErrors({ ...errors, ...errorObj })

    return !validated;
  }

  const handleSubmit = async () => {

    const validated = handleValidation();
    if (!validated) return;

    const postObj = {
      FirstName: data.fullName,
      Email: data.businessEmail,
      PhoneNumber: data.phoneNo,
      Description: data.description,
      MailType: 'ContactUs',
    }
    console.log(postObj);

    setLoading(true);
    const resp = await postRequest(`Contact/ContactUs`, postObj);
    if (resp.status === 200) {
      handleClose();
    }

    setLoading(false);
  }

  return (
    <Main>
      <main className="main-contact">
        <section class="a-section1">
          <div class="wrapper">
            <h1>Get in Touch</h1>
            <p>
              Have questions, inquiries, or need assistance? Feel free to reach
              out to us. Our team is here to help!
            </p>
            <div class="cards-wrapper">
              <div class="card">
                <h3>Contact Information</h3>
                <div class="contact-info">
                  Phone: <a href="tel:+61478420040"> +61 478 420 040</a>
                </div>
                <div class="contact-info">
                  Email:{" "}
                  <a href="mailto:info@allin1care.com.au"> info@allin1care.com.au</a>
                </div>
              </div>
              <div class="card">
                <h3>Business Hours</h3>
                <div class="business-hours">
                  Monday - Friday: 8:00 am - 7:00 pm
                </div>
                <div class="business-hours">
                  Note: Our emergency line operates 24/7.
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="a-section2">
          <div class="wrapper">
            <div class="left">
              <div>
                <h2>Quick Inquiry Form</h2>
                <p>
                  Have a specific question or want to schedule a consultation?
                  Fill out the form below, and we'll get back to you promptly.
                </p>
              </div>
              <div class="connect-with-us-wrapper">
                <div>Connect with us</div>
                <Link to="/" class="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
                      stroke="#FF4C98"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 11.3701C16.1234 12.2023 15.9813 13.0523 15.5938 13.7991C15.2063 14.5459 14.5932 15.1515 13.8416 15.5297C13.0901 15.908 12.2385 16.0397 11.4078 15.906C10.5771 15.7723 9.80977 15.3801 9.21485 14.7852C8.61993 14.1903 8.22774 13.4229 8.09408 12.5923C7.96042 11.7616 8.09208 10.91 8.47034 10.1584C8.8486 9.40691 9.4542 8.7938 10.201 8.4063C10.9478 8.0188 11.7978 7.87665 12.63 8.00006C13.4789 8.12594 14.2649 8.52152 14.8717 9.12836C15.4785 9.73521 15.8741 10.5211 16 11.3701Z"
                      stroke="#FF4C98"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.5 6.5H17.51"
                      stroke="#FF4C98"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Link>
                <Link to="https://www.facebook.com/profile.php?id=100085218183148&mibextid=LQQJ4d" target="_blank" class="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z"
                      stroke="#FF4C98"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Link>
                <Link to="/" class="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_325_810)">
                      <path
                        d="M14.8273 10.5858L23.934 0H21.776L13.8686 9.1915L7.55306 0H0.268799L9.81918 13.8992L0.268799 25H2.42692L10.7773 15.2935L17.447 25H24.7312L14.8267 10.5858H14.8273ZM11.8714 14.0216L10.9038 12.6376L3.20452 1.62459H6.51926L12.7327 10.5124L13.7003 11.8965L21.777 23.4493H18.4622L11.8714 14.0222V14.0216Z"
                        fill="#FF4C98"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_325_810">
                        <rect width="25" height="25" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>
              </div>
            </div>
            <div class="right">
              <div class="input-wrapper">
                <label>
                  Full name <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter full name"
                  name="fullname"
                  value={data.fullName} onChange={(e) => { handleChange(e.target.value, "fullName") }}
                />
                {errors.fullName && (
                  <ErrorMsg>{errors.fullName}</ErrorMsg>
                )}
              </div>
              <div class="input-wrapper">
                <label>
                  Email <span>*</span>
                </label>
                <input type="text" placeholder="name@ompany.com" name="email" value={data.businessEmail} onChange={(e) => { handleChange(e.target.value, "businessEmail") }} />
                {errors.businessEmail && (
                  <ErrorMsg>{errors.businessEmail}</ErrorMsg>
                )}
              </div>
              <div class="input-wrapper">
                <label>
                  Phone <span>*</span>
                </label>
                <input placeholder="+1254 215 012" name="phone" value={data.phoneNo} onChange={(e) => { handleChange(e.target.value, "phoneNo") }} />
                {errors.phoneNo && (
                  <ErrorMsg>{errors.phoneNo}</ErrorMsg>
                )}
              </div>
              <div class="input-wrapper">
                <label>
                  How can we help? <span>*</span>
                </label>
                <textarea
                  type="text"
                  placeholder="Tell us how we can help you..."
                  name="mesage"
                  rows={3}
                  value={data.description}
                  onChange={(e) => { handleChange(e.target.value, "description") }}
                />
                {errors.description && (
                  <ErrorMsg>{errors.description}</ErrorMsg>
                )}
              </div>
              <button onClick={handleSubmit} >Send Message</button>
            </div>
          </div>
        </section>
        <section class="a-section3">
          <div class="wrapper">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3378.237659978425!2d115.92021349999999!3d-32.1438845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a3296c87dfdb689%3A0x23e3835ad60b4838!2s10%20Shale%20Ct%2C%20Piara%20Waters%20WA%206112%2C%20Australia!5e0!3m2!1sen!2sin!4v1715356439100!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>          </div>
        </section>
      </main>

    </Main>
  );
};

export default Contact;
