import "./style.scss";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.png"

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header className="a-header">
      <Link to="/" className="a-logo-wrapper">
        <img src={logo} alt="logo" width={225} height="auto" />
      </Link>
      <button className="hamburger-btn" onClick={toggleNav}>☰</button>
      <nav className={`a-nav-wrapper ${isNavOpen ? 'show-nav' : 'hide-nav'}`}>
        <button className="close-btn" onClick={toggleNav}>˟</button>
        <ul>
          <li>
            <Link to="/" onClick={toggleNav}>Home</Link>
          </li>
          <li>
            <Link to="/services" onClick={toggleNav}>Services</Link>
          </li>
          <li>
            <Link to="/about" onClick={toggleNav}>About</Link>
          </li>
          <li>
            <Link to="/" onClick={toggleNav}>Blogs</Link>
          </li>
          <li>
            <Link to="/contact" className="btn" onClick={toggleNav}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
