import React from 'react';
import { PropTypes } from "prop-types";

import './styles.scss';

const NoContent = ({className, ...rest}) => {
  return (
    <div
        className={`v-no-content ${className}`}
        {...rest}
    >
      No content found
    </div>
  );
}

NoContent.propTypes = {
    className: PropTypes.string,
}

NoContent.defaultProps = {
    className: '',
}

export default NoContent;