import React, { useState } from 'react';
import ErrorMsg from "../../atoms/ErrorMsg/index";
import { postRequest } from "../../../utils/axios";
import { validateEmail, validatePhoneNumber } from "../../../utils/utils";

import image13 from "../../../assets/images/image13.png";
import './styles.scss';

const SendMessage = () => {

    const emptyData = {
        fullName: '',
        businessEmail: '',
        phoneNo: '',
        description: ''
    }

    const errorObj = {
        fullName: '',
        businessEmail: '',
        phoneNo: '',
        description: ''
    }

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState(emptyData)
    const [errors, setErrors] = useState(errorObj);

    const handleClose = () => {
        setErrors(errorObj);
        setData(emptyData);
    }

    const handleChange = (value, field) => {
        setData(prev => ({ ...prev, [field]: value }));
        if (errors[field] && value.trim() !== '') {
            setErrors(prev => ({ ...prev, [field]: '' }));
        }
    };

    const handleValidation = () => {
        const errorObj = {};
        let validated = false;
        if (data.fullName === '') {
            errorObj.fullName = 'Please enter your full name';
            validated = true;
        } else {
            errorObj.fullName = '';
        }

        if (data.businessEmail === '') {
            errorObj.businessEmail = 'Please enter your email';
            validated = true;
        } else if (!validateEmail(data.businessEmail)) {
            errorObj.businessEmail = 'Please enter valid email';
            validated = true;
        }
        else {
            errorObj.businessEmail = '';
        }

        if (data.phoneNo === '') {
            errorObj.phoneNo = 'Please enter phone no.';
            validated = true;
        } else if (!validatePhoneNumber(data.phoneNo)) {
            errorObj.phoneNo = 'Please enter valid phone no.';
            validated = true;
        }

        if (data.description === '') {
            errorObj.description = 'Please enter description';
            validated = true;
        } else {
            errorObj.description = '';
        }

        setErrors({ ...errors, ...errorObj })

        return !validated;
    }

    const handleSubmit = async () => {

        const validated = handleValidation();
        if (!validated) return;

        const postObj = {
            FirstName: data.fullName,
            Email: data.businessEmail,
            PhoneNumber: data.phoneNo,
            Description: data.description,
            MailType: 'ContactUs',
        }
        console.log(postObj);

        setLoading(true);
        const resp = await postRequest(`Contact/ContactUs`, postObj);
        if (resp.status === 200) {
            handleClose();
        }

        setLoading(false);
    }

    return (
        <div className="message-wrapper">
            <div className="wrapper">
                <div className="left">
                    <div className="img-wrapper">
                        <img src={image13} alt="icon" />
                        <div className="content-wrapper content-wrapper-1">
                            <div className="icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M15.0475 5C16.0242 5.19057 16.9219 5.66826 17.6256 6.37194C18.3292 7.07561 18.8069 7.97326 18.9975 8.95M15.0475 1C17.0768 1.22544 18.9691 2.13417 20.4137 3.57701C21.8584 5.01984 22.7695 6.91101 22.9975 8.94M21.9975 16.92V19.92C21.9986 20.1985 21.9416 20.4742 21.83 20.7293C21.7184 20.9845 21.5548 21.2136 21.3496 21.4019C21.1443 21.5901 20.9021 21.7335 20.6382 21.8227C20.3744 21.9119 20.0949 21.9451 19.8175 21.92C16.7403 21.5856 13.7845 20.5341 11.1875 18.85C8.77132 17.3147 6.72283 15.2662 5.18749 12.85C3.49747 10.2412 2.44573 7.27099 2.11749 4.18C2.0925 3.90347 2.12537 3.62476 2.21399 3.36162C2.30262 3.09849 2.44506 2.85669 2.63226 2.65162C2.81945 2.44655 3.0473 2.28271 3.30128 2.17052C3.55527 2.05833 3.82983 2.00026 4.10749 2H7.10749C7.5928 1.99522 8.06328 2.16708 8.43125 2.48353C8.79922 2.79999 9.03957 3.23945 9.10749 3.72C9.23411 4.68007 9.46894 5.62273 9.80749 6.53C9.94204 6.88792 9.97115 7.27691 9.8914 7.65088C9.81164 8.02485 9.62635 8.36811 9.35749 8.64L8.08749 9.91C9.51105 12.4135 11.5839 14.4864 14.0875 15.91L15.3575 14.64C15.6294 14.3711 15.9726 14.1858 16.3466 14.1061C16.7206 14.0263 17.1096 14.0555 17.4675 14.19C18.3748 14.5286 19.3174 14.7634 20.2775 14.89C20.7633 14.9585 21.2069 15.2032 21.524 15.5775C21.8412 15.9518 22.0097 16.4296 21.9975 16.92Z"
                                        stroke="#662771"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                            <p className="type2">
                                For Urgent Enquiries call us at <br /> +61 478 420 040{" "}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div className='right-wrapper'>
                        <h2>
                            <span>Send us a message.</span> We will get back to you ASAP!
                        </h2>
                        <div class="input-wrapper">
                            <label>
                                Full name <span>*</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter full name"
                                name="fullname"
                                value={data.fullName} onChange={(e) => { handleChange(e.target.value, "fullName") }}
                            />
                            {errors.fullName && (
                                <ErrorMsg>{errors.fullName}</ErrorMsg>
                            )}
                        </div>
                        <div class="input-wrapper">
                            <label>
                                Email <span>*</span>
                            </label>
                            <input type="text"
                                placeholder="name@ompany.com"
                                name="email"
                                value={data.businessEmail}
                                onChange={(e) => { handleChange(e.target.value, "businessEmail") }}
                            />
                            {errors.businessEmail && (
                                <ErrorMsg>{errors.businessEmail}</ErrorMsg>
                            )}
                        </div>
                        <div class="input-wrapper">
                            <label>
                                Phone <span>*</span>
                            </label>
                            <input placeholder="+1254 215 012" name="phone" value={data.phoneNo} onChange={(e) => { handleChange(e.target.value, "phoneNo") }} />
                            {errors.phoneNo && (
                                <ErrorMsg>{errors.phoneNo}</ErrorMsg>
                            )}
                        </div>
                        <div class="input-wrapper">
                            <label>
                                How can we help? <span>*</span>
                            </label>
                            <textarea
                                type="text"
                                placeholder="Tell us how we can help you..."
                                name="mesage"
                                rows={3}
                                value={data.description}
                                onChange={(e) => { handleChange(e.target.value, "description") }}
                            />
                            {errors.description && (
                                <ErrorMsg>{errors.description}</ErrorMsg>
                            )}
                        </div>
                        <button onClick={handleSubmit} >Send Message</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SendMessage;