import React from "react";
import { Link } from "react-router-dom";
import HomeSlider from "../../components/organs/review";
import SendMessage from "../../components/organs/SendMessage";
import Main from "../../layout/Main";

import image4 from "../../assets/images/image4.png"
import image20 from "../../assets/images/image20.png"
import image21 from "../../assets/images/image21.png"
import image22 from "../../assets/images/image22.png"
import image23 from "../../assets/images/image23.png"

import "./styles.scss";


const Services = () => {
  return (
    <Main className="main-services">
      <nav className="a-breadcrumb">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <span> &nbsp; Supported Independent Living </span>
          </li>
        </ul>
      </nav>

      <section className="a-section1">
        <div className="wrapper">
          <h1>
            Embracing Independence with
            <div>All In 1 Care Supported Independent Living</div>
          </h1>
          <p>
            Unlock Independence and Support with All In One Care's Tailored
            Supported Independent Living Services. Discover Your Perfect Home,
            Personalized Support Plans, and a Community-Centric Approach to
            Living. Explore Now for a Life of Fulfillment and Empowerment!
          </p>
          <Link className="button" to="/contact">
            Contact Us
          </Link>
          <img src={image20} alt="Hero" />
        </div>
      </section>

      <section className="a-section2">
        <div className="wrapper">
          <div className="left">
            <h2>What is Supported Independent Living?</h2>
            <p>
              Unlock Independence and Support with All In One Care's Tailored
              Supported Independent Living Services. Discover Your Perfect
              Home, Personalized Support Plans, and a Community-Centric
              Approach to Living. Explore Now for a Life of Fulfillment and
              Empowerment!
            </p>
            <h2>Support Offered by All in 1 Care</h2>
            <p>
              Once settled in your new home, our support covers a wide range
              of services, from daily tasks to skill-building activities and
              overnight assistance, ensuring you're fully covered in various
              aspects of your life:
            </p>
            <ul>
              <li>Cleaning and laundry services</li>
              <li>Nutrition planning and cooking assistance</li>
              <li>Personal care routines like showering and dressing</li>
              <li>Accompanying you to appointments</li>
              <li>Medication management</li>
              <li>Exploring and adopting new hobbies</li>
              <li>Learning to navigate public transportation</li>
            </ul>
          </div>
          <div className="right">
            <img src={image4} alt="questions" />
            <div className="content-wrapper">
              <h2>Got questions?</h2>
              <p>
                Need to get in touch?Our Customer Service Team would love to
                help
              </p>
              <Link className="button" to="/contact">
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="a-section3">
        <div className="wrapper">
          <h2>
            All In One Care's <div>Comprehensive Services</div>
          </h2>
          <div className="cards-wrapper">
            <div className="card">
              <div className="left">
                <img
                  src={image21}
                  alt="Explore Diverse Living Options for you"
                />
              </div>
              <div className="right">
                <h3>Explore Diverse Living Options for you</h3>
                <p>
                  Discover a variety of disability accommodations, from shared
                  spaces fostering community to private residences providing
                  privacy. Your comfort and choice are central as we assist in
                  finding the ideal living situation that suits your
                  lifestyle.{" "}
                </p>
              </div>
            </div>
            <div className="card">
              <div className="left order2">
                <h3>Personalized Care Plans</h3>
                <p>
                  Our team works closely with you to create bespoke support
                  plans. By aligning our services with your objectives, we
                  empower you to achieve your goals while ensuring your unique
                  needs are met every step of the way.
                </p>
              </div>
              <div className="right order1">
                <img
                  src={image22}
                  alt="Personalized Care Plans"
                />
              </div>
            </div>
            <div className="card">
              <div className="left">
                <img
                  src={image23}
                  alt="Guided Support Selection"
                />
              </div>
              <div className="right">
                <h3>Guided Support Selection</h3>
                <p>
                  Making decisions about support and living arrangements can
                  be daunting. Let us be your compass. We'll navigate the
                  available choices and assist you in making informed
                  decisions aligned with your preferences and requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeSlider />
      <SendMessage />

    </Main>
  );
};

export default Services;
