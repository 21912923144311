import React from "react";

import Main from "../../layout/Main";
import Review from "../../components/organs/review";
import SendMessage from "../../components/organs/SendMessage";

import ndisImage from "../../assets/icons/ndis-image-color.svg";
import ndisLogo from "../../assets/icons/ndis-logo.svg";

import { Link } from "react-router-dom";

import "./styles.scss";


const About = () => {
  return (
    <Main className="about-page">
      <div className="wrapper">
        <section className="a-section1">
          <div className="wrapper">
            <div>
              <h1>
                Empowering Lives, <span>Inspiring Futures</span>
              </h1>
              <p>
                Crafted from 20+ years of healthcare expertise, All in 1 Care
                stands as a beacon of support, empowering those with
                psychosocial disabilities to thrive. Join us on a journey
                dedicated to fostering independence, compassion, and holistic
                well-being.
              </p>
              <Link className="btn" to="/contact">
                Contact Us
              </Link>
            </div>
          </div>
        </section>

        <section className="a-section2">
          <div className="wrapper">
            <h2>Our Story</h2>
            <p>
              Founded in 2020 by experienced healthcare professionals, All in 1
              Care is dedicated to empowering individuals with psychosocial
              disabilities. Our founding team, with over two decades of
              collective experience as registered nurses in Australian
              healthcare, noticed prevalent gaps in NDIS support. Leveraging our
              extensive background in public mental health and disability
              services, we offer personalized care aligned with your goals. We
              meticulously match aspirations with our skilled support staff and
              provide 24/7 assistance through dedicated registered nurses,
              ensuring optimal outcomes even in challenging situations.
            </p>
            <div className="images">
              <img src={ndisLogo} />
              <img src={ndisImage} />
            </div>
          </div>
        </section>

        <section className="a-section3">
          <div className="wrapper">
            <div className="cards-wrapper">
              <div className="card card1">
                <div>
                  <h3>Our Vision</h3>
                  <p>
                    To enhance the lives of individuals grappling with mental
                    illness and disabilities while fostering emotional wellness
                    for both individuals and families. We endeavor to provide
                    essential services.{" "}
                  </p>
                </div>
              </div>
              <div className="card card2">
                <div>
                  <h3>Our Mission</h3>
                  <p>
                    Our mission to provide best available core supports services
                    by giving our participants a voice, choice and opportunity
                    to be heard. Also work towards enhancing mental health
                    awareness, promote individual empowerment .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="a-section4">
          <div className="wrapper">
            <h2>
              Hear from
              <span>
                our customers
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="418"
                  height="10"
                  viewBox="0 0 418 10"
                  fill="none"
                >
                  <path
                    d="M1 7.99984C1 7.99984 130.855 1.79043 214.279 2.00032C293.586 2.19986 417 7.99984 417 7.99984"
                    stroke="#FF4C98"
                    stroke-width="2.53428"
                  />
                </svg>
              </span>
            </h2>
            <Review />
          </div>
        </section>

        <SendMessage />
      </div>
    </Main>
  );
};

export default About;
