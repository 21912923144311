import React from 'react';
import {
    createBrowserRouter,
    RouterProvider,
    Navigate
} from "react-router-dom";
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact'
import Services from './pages/services'
import PrivacyPolicy from './pages/privacy-policy';
import PagesInfo from './pages/pages-info';

const router = createBrowserRouter([
    {
        path: "/",
        element:<Home />
    },
    {
        path:"*",
        element: <Navigate to="/" replace />
    },
    {
        path: "/about",
        element: <About />
    },
    {
        path: "/contact",
        element: <Contact />
    },
    {
        path: "/services",
        element: <Services />
    },
    {
        path: "/:categoryTypeName/:pageUrl",
        element: <PagesInfo />,
      },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicy />
    }
])

const Routes = () => {
    return (
        <RouterProvider router={router} />
    );
}

export default Routes;
